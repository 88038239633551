$ = jQuery;



(function($) {
    "use strict"; // Start of use strict

    //addSkin({clicktag:'http://google.ca', image:'https://files.slack.com/files-pri/T04MWUJRX-F12B80D4Y/pub_dominance.jpg'});
    window.addSkin = function addSkin(params){
        console.log(params);
        if('matchMedia' in window && window.matchMedia("(min-width: 1025px)").matches){
            jQuery('.featured--home, .single-news').addClass('featured--dominance');
            var $a = jQuery('<a/>',{
                href: params.clicktag,
                class: 'skin',
                target: '_blank',
                style: 'background-image:url('+ params.image +')'
            }).prependTo('.featured--dominance');
            console.log($a);


            jQuery('body').addClass('has-skin');
        }
    }

    MultiSelect.init();


    $('.filterContainer').on('click', '.js-showFilter', function(e) {
        e.preventDefault();
        $('.filterContainer').find('.filters_search').toggleClass("is-open");
    });



    $('.accordion-tabs').on('click', '.tab-link', function(event) {
        if (!$(this).hasClass('is-active')) {
            event.preventDefault();
            var accordionTabs = $(this).closest('.accordion-tabs');
            accordionTabs.find('.is-open').removeClass('is-open');

            $(this).next().toggleClass('is-open');
            accordionTabs.find('.is-active').removeClass('is-active');
            $(this).addClass('is-active');
        } else {
            event.preventDefault();
            $(this).removeClass('is-active');
            $(this).next().removeClass('is-open');
        }
    });



    $.fn.cssMore = function(options) {

        var settings = $.extend({
            nbmax     : 6
        }, options),
        $items = $(this).find('ul > li');

        if($items.length>settings.nbmax){
            $(this).find('.btn-more').css('display', 'inline-block').hide().fadeIn();
        }

        $items.each(function(){
            $(this).hide();
            if($(this).index()<settings.nbmax){
                $(this).show().fadeIn();
            }
        })

        $(this).find('.btn-more').on('click', function(e) {
            e.preventDefault();
            var $toreveal = $items.filter(":hidden").slice(0,settings.nbmax);
            $toreveal.each(function(){
                if($(this).index($toreveal)<settings.nbmax){
                    $(this).fadeIn();
                }
            })
            if($items.filter(":hidden").length==0){
                $(this).hide();
            }
        });
    };

    $('<div class="overlay" />').appendTo('body').on('click', function() {
        $('body').removeClass("has-mobilenav-open has-usernav-open");
        $('.navicon-button, .search-form').removeClass("is-open");
    });

    $('.repertoireFilter__listing--daddy').find('span').on('click', function(e){
        e.preventDefault();
        $(this).parent().toggleClass("is-open");
    });

    $("a.navicon-button").on('click', function(e){
        e.preventDefault();
        $(this).toggleClass("is-open");
        $('.search-form').removeClass("is-open");
        $('body').removeClass("has-usernav-open").toggleClass("has-mobilenav-open");
    });

    $("a.user-button, a.user-close").on('click', function(e){
        e.preventDefault();
        $('.navicon-button, .search-form').removeClass("is-open");
        $('body').removeClass("has-mobilenav-open").toggleClass("has-usernav-open");
    });

    $("a.search-button, a.search-close").on('click', function(e){
        e.preventDefault();
        $('.navicon-button').removeClass("is-open");
        $('body').removeClass("has-mobilenav-open has-usernav-open");
        $('.search-form').toggleClass("is-open");
        $('#searchkeyword').focus();
    });

    // $('iframe').each(function(){
    //     $(this).wrap('<div class="iframe-wrapper"></div>');
    // });

    $('.gallery').cssMore();

    $('.gallery ul').find('a').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true, // set to true to enable gallery
            preload: [1,2], // read about this option in next Lazy-loading section
            navigateByImgClick: true
        },
        callbacks: {
            beforeOpen: function(){
                // add this code after popup JS file is included
                $.magnificPopup.instance.next = function() {
                    // if index is not last, call parent method
                    if($.magnificPopup.instance.index < $.magnificPopup.instance.items.length - 1) {
                        // You may call parent ("original") method like so:
                        $.magnificPopup.proto.next.call(this /*, optional arguments */);
                    }
                };
                $.magnificPopup.instance.prev = function() {
                    // if index is not first, call parent method
                    if($.magnificPopup.instance.index > 0) {
                        // You may call parent ("original") method like so:
                        $.magnificPopup.proto.prev.call(this /*, optional arguments */);
                    }
                };

                $.magnificPopup.instance.toggleArrows = function() {
                    // if index is not last, show the Next-Image Arrow Button:
                    if($.magnificPopup.instance.index < $.magnificPopup.instance.items.length - 1) {
                        $(".mfp-arrow-right").show();
                    }
                    // if index is last, hide the Next-Image Arrow Button:
                    if($.magnificPopup.instance.index == $.magnificPopup.instance.items.length - 1) {
                        $(".mfp-arrow-right").hide();
                    }

                    // if index is not first, show the Previous-Image Arrow Button:
                    if($.magnificPopup.instance.index > 0) {
                        $(".mfp-arrow-left").show();
                    }
                    // if index is first, hide the Previous-Image Arrow Button:
                    if($.magnificPopup.instance.index == 0) {
                        $(".mfp-arrow-left").hide();
                    }
                };
                $.magnificPopup.instance.updateItemHTML = function() {
                    $.magnificPopup.instance.toggleArrows();
                    // You may call parent ("original") method like so:
                    $.magnificPopup.proto.updateItemHTML.call(this /*, optional arguments */);
                };
            },
            open: function() {
                // Will fire when this exact popup is opened
                // this - is Magnific Popup object

                this.toggleArrows.call(this);
              },
            change: function() {
                console.log(this.index); // Direct reference to your popup element
                if ((this.index + 1)%6 === 0) {
                    $('.gallery').find('.btn-more').trigger('click');
                }
            }
        },
        zoom: {
            enabled: true
        }
    });

    $('#filterSubmit').on('click', function(e){
        e.preventDefault();
        var checkedValue = {};
        $('#filters_search').find('input:checked').each(function(){
            var split = $(this).val().split('=');
            if(typeof checkedValue[split[0]] === 'undefined'){
                checkedValue[split[0]] = [];
            }
            checkedValue[split[0]].push(split[1]);
        });
        var url = '';
        var index_key = 0;
        for(var key in checkedValue){
            var obj = checkedValue[key];
            url += key + '=';
            for(var value in obj){
                if(parseInt(value) + 1 == obj.length){
                    url += obj[value];
                }else{
                    url += obj[value] + '_';
                }
            }
            if(index_key + 1 != Object.keys(checkedValue).length){
                url += '&';
            }
            index_key++;
        }
        window.location.href = location.protocol + '//' + location.host + location.pathname + '?' + url;
    });

    $('#filterReset').on('click', function(e){
        e.preventDefault();
        window.location.href = location.protocol + '//' + location.host + location.pathname;
    });

    $('[js-category-select]').on('change', function(){
        window.location.href = $(this).val() + location.search;
    });

    $('.lightSlider').lightSlider({
      gallery: true,
      item: 1,
      loop:true,
      slideMargin: 0,
      thumbItem: 6,
      controls:false,
    });

})(jQuery); // End of use strict

var lastScrollTop = 0;
$(window).on('DOMContentLoaded load resize scroll orientationchange', function (event){
    var st = $(this).scrollTop();
    if (st > lastScrollTop && $(document).scrollTop() > $(window).height()/2){
        // downscroll code
        $('.main-nav').addClass('is-hidden');
    } else {
        // upscroll code
        $('.main-nav').removeClass('is-hidden');
    }
    lastScrollTop = st;

    var owl = $(".grid--featured").find('.row');

    if ( $(window).width() < 768 ) {
        if ( owl.length ) {
            owl.owlCarousel({
                singleItem:true,
                loop: false
            });
            owl.addClass('is-owled');
        }
    } else {
        if ( owl.length && owl.hasClass('is-owled') ) {
            owl.removeClass('is-owled').data('owlCarousel').destroy();
        }
    }
    if ( $(window).width() >= 768 ) {
        $('.accordion-tabs').find('.tab-content').removeClass('is-open');
        $('.accordion-tabs').find('.tab-link').removeClass('is-active');
    }



});
