$('.js-select').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option:selected').eq(0).text());

    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function(e) {
        e.stopPropagation();
        if ($('.select-styled').hasClass('is-active')) {
          $(this).removeClass('is-active').next('.select-options').hide();
        }
        else {
          $(this).toggleClass('is-active').next('.select-options').toggle();
        }
    });

    $listItems.each(function(){
        $(this).click(function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('is-active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $this.eq($(this).index()).prop('selected',true);
            $this.trigger('change');
        })
    });



    $(document).click(function() {
        $styledSelect.removeClass('is-active');
        $list.hide();
    });

});
