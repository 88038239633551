var MultiSelect = (function(window, undefined) {

    'use strict';

    var _setUp = () => {

        if ($('[js-custom-multiple-select]').length) {

            $('[js-custom-multiple-select]').click(function(e) {
                e.preventDefault();
                e.stopPropagation();

                $('.select-options').hide();

                var 
                    $elem = $(this),
                    $elemMenu = $elem.siblings('.select-options');
                
                if ($elemMenu.is(':hidden')) {
                    $elemMenu.show();
                } else {
                    $elemMenu.hide();
                }
            });
            
            $(document).on('click',function(e) {
                if ($('[js-custom-multiple-select]').length > 0) {
                    //Checking if we are clicking on the options before closing the select
                    if ($(e.target).parents('.select-options').length <= 0) {
                        var 
                            $elem = $('[js-custom-multiple-select]'),
                            $elemMenu = $elem.siblings('.select-options');

                        if ($elemMenu.is(':visible')) {
                            $elemMenu.hide();
                        }
                     }   
                } 
            });
        }

    },

    init = () => {
        _setUp();
    },

    public_API = {
        init: init
    };

    return public_API;

})(window, undefined);