$(function() {
    window.ParsleyValidator.setLocale('fr');

    // ==============================
    // INIT PLACEHOLDER
    // ==============================
    //$('input, textarea').placeholder();


    // TABS
    $('.box_tabs li').click(function(event) {
        event.preventDefault();
        var index = $(this).index();
        $('.box_tabs li').removeClass('active');
        $(this).addClass('active');
        $('.tabpage').removeClass('active');
        $('#tabs_' + index).addClass('active');
    });
    // $('.box_tabs a').click(function (e) {
    //     e.preventDefault();
    //     $(this).tab('show');
    // });


    // $('[data-tab]').click(function (e) {
    //      //e.preventDefault();
    //      $('.box_tabs a[href="'+($(this).attr('href'))+'"]').tab('show');
    //  });

//     $('#inscription').parsley().subscribe('parsley:form:validate', function (formInstance) {
//
//        // if one of these blocks is not failing do not prevent submission
//        // we use here group validation with option force (validate even non required fields)
////        if ($("#chk_confirmation:checked").length > 0)
////          return;
//
//        // else stop form submission
//        formInstance.submitEvent.preventDefault();
//
//        // and display a gentle message
//        $('.invalid-form-error-message')
//          .html("Vous devez accepter le règlement du concours.")
//          .addClass("parsley-required");
//        return;
//     });

    $('#inscription').submit(function(e) {
        if ( $(this).parsley().isValid() ) {
            $.ajax({
                url:'/concours/validation/',
                dataType :'JSON',
                type:'POST',
                data: $(this).serialize(),
                success:function(response){
                    if (response.error) {
                        $('.error-container').show();
                        $('.error-container ul').html('');
                        $('.recaptcha-error').html('');
                        $.each(response.errorfield, function() {
                            if (this.field == 'captchaCode') {
                                $('.recaptcha-error').html(this.txt);
                            } else {
                                $('.error-container ul').append('<li>' + this.txt + '</li>');
                            }
                        });
                    } else {
                        if (response.redirection) {
                            window.location.href = response.redirection;
                        }
                    }

                }
            });
        }
        e.preventDefault();
        return false;
    });

});